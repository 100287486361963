import request from '@/utils/request'
// import Vue from 'vue'

// 获取navbar栏目树
export const getNavChannel = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getNavChannel`,
        method: 'GET',
        params: data
    })
}

// 获取第二级栏目
export const getChildChannel = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getChildChannel`,
        method: 'GET',
        params: data
    })
}

// 分页查询文章列表接口
export const articles = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/articles`,
        method: 'POST',
        data: data
    })
}

// 获取文章列表接口
export const getArtList = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getArtList`,
        method: 'POST',
        data: data
    })
}

// 根据channelUuid获取文章详情接口
export const getArtInfoByChannel = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getArtInfoByChannel`,
        method: 'POST',
        data: data
    })
}

// 获取文章详情接口
export const getArtInfo = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getArtInfo`,
        method: 'POST',
        data: data
    })
}

// 获取头条文章列表接口
export const getTopArtList = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getTopArtList`,
        method: 'POST',
        params: data
    })
}

// 获取广告列表
export const getAdvert = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getAdvert`,
        method: 'GET',
        params: data
    })
}

// 获取频道详情
export const getChannelInfo = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getChannelInfo`,
        method: 'GET',
        params: data
    })
}

// 分页查询焦点图不为空文章接口
export const picArticles = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/picArticles`,
        method: 'POST',
        data: data
    })
}

// 获取集团站群列表
export const getApplicationList = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getApplicationList`,
        method: 'GET',
        params: data
    })
}

// 举报
export const addAccuseInform = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/addAccuseInform`,
        method: 'POST',
        data: data
    })
}

//信访
export const addLettersVisits = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/addLettersVisits`,
        method: 'POST',
        data: data
    })
}

//信访页面字典值
export const getDict = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/getDict`,
        method: 'POST',
        data: data
    })
}

//分页查询焦点图不为空且推荐焦点图文章接口
export const picArticlesRecommend = data => {
        return request({
            url: `${process.env.VUE_APP_URL}/web/picArticlesRecommend`,
            method: 'POST',
            data: data
        })
    }
    // 搜索文章列表接口
export const articleSearch = data => {
    return request({
        url: `${process.env.VUE_APP_URL}/web/articleSearch`,
        method: 'POST',
        data: data
    })
}